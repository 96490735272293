<template>
  <div>
    <router-view/>
  </div>
</template>

<script>

import axios from "axios";

export default {
  components: {},
  data() {
    return {
      configuration: false,
      games: false
    }
  },
  mounted() {
    this.checkApiKey();
    this.initAuthInterceptors();
  },
  methods: {
    checkApiKey() {
      const apiKey = localStorage.getItem('apiKey');
      if (apiKey) {
        this.$store.commit('root/setApiKey', apiKey);
      }
    },
    initAuthInterceptors() {
      const _that = this;
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401 && _that.$route.name !== 'login') {
          localStorage.removeItem('apiKey');
          _that.$router.push({name: 'login'});
        }
        return Promise.reject(error)
      })
    }
  }
}
</script>
