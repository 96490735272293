<template>
  <div>
    <router-view />
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      configuration: false,
      games: false
    }
  },
  mounted() {
  }
}
</script>
