import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const resourceStructure = {
    author: null,
    content: null,
    createdAt: null,
    deletedAt: null,
    excerpt: null,
    group: 'default',
    id: null,
    keyName: null,
    locale: null,
    menuTitle: null,
    parentId: null,
    publishedAt: null,
    redirectUrl: null,
    status: '',
    // template: null,
    title: null,
    unpublishedAt: null,
    updatedAt: null
}

const state = () => ({
    resourceStructure: null,
    resourceDetails: resourceStructure,
    activeResourceId: null,
    activeResourceParentId: null,
    resourceFile: null,
    resourceTranslations: null,
    resourceChildElements: null,
    localeList: null,
    treeAttribute: 'title',
    locale: 'en',
    group: 'default',
    isLoading: false,
    showValidationStatus: false,
    availableParents: [],
    isEditResource: false,
    parentIdToOpenTree: null,
    treeFilterPhrase: ''
});

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};