const CmsApp = () => import(/* webpackChunkName: "CmsApp" */ './containers/CmsApp');
const CmsResourcesList = () => import(/* webpackChunkName: "CmsResourcesList" */ './containers/CmsResourcesList');
const CmsResourceDetails = () => import(/* webpackChunkName: "CmsResourceDetails" */ './containers/CmsResourceDetails');
const General = () => import(/* webpackChunkName: "General" */ './components/General');
const Advanced = () => import(/* webpackChunkName: "Advanced" */ './components/Advanced');
const Translations = () => import(/* webpackChunkName: "Translations" */ './components/Translations');
const ChildElements = () => import(/* webpackChunkName: "ChildElements" */ './components/ChildElements');
const Attributes = () => import(/* webpackChunkName: "General" */ './components/Attributes');

export const cmsRouting = [
    {
        path: '/cms', name: 'cms', component: CmsApp,
        children: [
            { path: '/', redirect: 'resources-list' },
            {
                path: 'resources-list', name: 'resources-list', component: CmsResourcesList
            },
            {
              path: 'resource-create', name: 'resource-create', component: CmsResourceDetails,
              children: [
                { path: '/', redirect: 'general' },
                { path: 'general', name: 'cms-general-create', component: General },
                { path: 'advanced', name: 'cms-advanced-create', component: Advanced },
                { path: 'translations', name: 'cms-translations-create', component: Translations},
                { path: 'child-elements', name: 'cms-child-elements-create', component: ChildElements},
                { path: 'attributes', name: 'cms-attributes-create', component: Attributes }
              ]
            },
            {
                path: 'resource-details/:id', component: CmsResourceDetails, props: { isCreateType: false },
                children: [
                    { path: '/', redirect: 'general' },
                    { path: 'general', name: 'cms-general', component: General },
                    { path: 'advanced', name: 'cms-advanced', component: Advanced },
                    { path: 'translations', name: 'cms-translations', component: Translations, props: { isCreateType: false }},
                    { path: 'child-elements', name: 'cms-child-elements', component: ChildElements, props: { isCreateType: false }},
                    { path: 'attributes', name: 'cms-attributes', component: Attributes }
                ]
            },
        ]
    },
];