export default {
    SET_AVAILABLE_PARENTS(state, value) {
        state.availableParents = value;
    },
    SET_IS_LOADING(state, value) {
        state.isLoading = value;
    },
    SET_SHOW_VALIDATION_STATUS(state, value) {
        state.showValidationStatus = value;
    },
    SET_RESOURCE_STRUCTURE(state, data) {
        state.resourceStructure = data;
    },
    SET_IS_EDIT_RESOURCE(state, data) {
        state.isEditResource = data;
    },
    SET_ACTIVE_RESOURCE_ID(state, data) {
        state.activeResourceId = data;
    },
    SET_PARENT_ID_TO_OPEN_TREE(state, data) {
        state.parentIdToOpenTree = data;
    },
    SET_ACTIVE_RESOURCE_PARENT_ID(state, data) {
        state.activeResourceParentId = data;
    },
    SET_TREE_FILTER_PHRASE(state, data) {
      state.treeFilterPhrase = data;
    },
    SET_RESOURCE_DETAILS_INITIAL_VALUE(state) {
      state.resourceDetails = {
            author: null,
            content: '',
            createdAt: null,
            deletedAt: null,
            excerpt: '',
            group: 'default',
            id: null,
            keyName: null,
            lft: null,
            locale: state.locale,
            lvl: null,
            menuTitle: null,
            parentId: null,
            publishedAt: null,
            redirectUrl: null,
            rgt: null,
            status: '',
            // template: null,
            title: null,
            unpublishedAt: null,
            updatedAt: null,
        };
    },
    SET_LOCALE(state, value) {
      state.locale = value;
    },
    SET_TREE_ATTRIBUTE(state, value) {
        state.treeAttribute = value;
    },
    SET_GROUP(state, value) {
        state.group = value;
    },
    SET_LOCALE_LIST(state, data) {
        state.localeList = data;
    },
    SET_RESOURCE_DETAILS(state, data) {
        state.resourceDetails = data;
    },
    SET_RESOURCE_TRANSLATIONS(state, data) {
        state.resourceTranslations = data;
    },
    SET_RESOURCE_CHILD_ELEMENTS(state, data) {
        state.resourceChildElements = data;
    },
    SET_RESOURCE_FORM_TITLE(state, value) {
        state.resourceDetails.title = value;
    },
    SET_RESOURCE_FORM_MENU_TITLE(state, value) {
        state.resourceDetails.menuTitle = value;
    },
    SET_RESOURCE_FORM_PARENT_ID(state, value) {
        state.resourceDetails.parentId = value;
    },
    SET_RESOURCE_FORM_EXCERPT(state, value) {
        state.resourceDetails.excerpt = value;
    },
    SET_RESOURCE_FORM_CONTENT(state, value) {
        state.resourceDetails.content = value;
    },
    SET_RESOURCE_FORM_STATUS(state, value) {
        state.resourceDetails.status = value;
    },
    SET_RESOURCE_FORM_LOCALE(state, value) {
        state.resourceDetails.locale = value;
    },
    SET_RESOURCE_FORM_AUTHOR(state, value) {
        state.resourceDetails.author = value;
    },
    SET_RESOURCE_FORM_KEY_NAME(state, value) {
        state.resourceDetails.keyName = value;
    },
    SET_RESOURCE_FORM_SLUG(state, value) {
        state.resourceDetails.slug = value;
    },
    // SET_RESOURCE_FORM_TEMPLATE(state, value) {
    //     state.resourceDetails.template = value;
    // },
    SET_RESOURCE_FORM_REDIRECT_URL(state, value) {
        state.resourceDetails.redirectUrl = value;
    },
    SET_RESOURCE_FORM_PUBLISHED_AT(state, value) {
        state.resourceDetails.publishedAt = value;
    },
    SET_RESOURCE_FORM_UNPUBLISHED_AT(state, value) {
        state.resourceDetails.unpublishedAt = value;
    },
    SET_RESOURCE_FORM_FILE(state, value) {
        state.resourceFile = value;
    }
};
